import {
  Flex, Text, VisuallyHidden
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { setCookie } from 'nookies';

import NextChakraLink from '../NextChakraLink/NextChakraLink';

function LanguageSwitcher() {
  const { t } = useTranslation('common');
  const { locale, asPath } = useRouter();

  const setLocaleCookie = () => {
    setCookie(null, 'NEXT_LOCALE', locale === 'da' ? 'en' : 'da');
  };

  return (
    <Flex textAlign="center">
      {/* Current locale */}
      <Text display={{ base: 'none', sm: 'block' }}>{t('opposite-locale')}</Text>

      {/* Switch link */}
      <NextChakraLink
        href={asPath}
        locale={locale === 'da' ? 'en' : 'da'}
        onKeyPress={setLocaleCookie}
        onClick={setLocaleCookie}
        color={{ base: 'white', sm: 'greyHover' }}
        textDecoration={{ base: 'underline', sm: 'none' }}
        className="arrow-link arrow-link--before"
        sx={{
          '@media (min-width: 30em)': {
            '&::before': {
              marginLeft: '1',
            },
          },
        }}
      >
        <VisuallyHidden lang={locale === 'da' ? 'en' : 'da'}>{t('switch-to-lang')} {locale === 'da' ? 'English' : 'Dansk'}</VisuallyHidden>
        <Text as="span" aria-hidden="true">{t('locale')}</Text>
      </NextChakraLink>
    </Flex>
  );
}

export default LanguageSwitcher;
