import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';

import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import NextChakraLink from '../NextChakraLink/NextChakraLink';
import SearchInput from '../SearchInput/SearchInput';

function Header() {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const searchButtonRef = useRef();
  const searchInputRef = useRef();

  /* Create a custom property with the val of the height of the header */
  const headerRef = useRef();
  useEffect(() => {
    const setHeaderHeightProperty = () => {
      if (headerRef.current) {
        const height = headerRef.current.clientHeight;
        // Plus one for border
        document.documentElement.style.setProperty(
          '--header-height',
          `${height + 1}px`
        );
      }
    }

    setHeaderHeightProperty();

    // Update on resize
    window.addEventListener('resize', setHeaderHeightProperty);

    return () => {
      window.removeEventListener('resize', setHeaderHeightProperty);
    };
  }, []);

  return (
    <Box
      ref={headerRef}
      as="header"
      height={{ md: '158px' }}
      borderBottom="1px"
      zIndex="100"
      position="sticky"
      maxW="2000px"
      mx="auto"
      className="page-border"
    >
      <Grid
        templateColumns="11.54% 11.54% 15.38% 11.54% 11.54% 15.38% 11.54% 11.54%"
        alignItems="end"
        height="100%"
      >
        <GridItem
          colSpan="5"
          colStart="1"
          borderRight="1px"
          height="100%"
          px="sideSpace"
          py={{ base: '4', md: '0' }}
        >
          <Flex height="100%" justify="space-between" gap="4">
            {/* Logo */}
            <NextChakraLink
              href="/"
              alignSelf="center"
              minWidth="119px"
            >
              <Image
                src={`/images/logos/SNM_${locale}_logo_white.svg`}
                fallbackSrc={'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="200" height="89"></svg>'}
                alt={t('natural-history-museum-denmark')}
                width="auto"
                height={{ base: '53px', md: 'calc(70px + 19 * ((100vw - 600px) / 1080))' }}
                transition="height 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94)"
              />
            </NextChakraLink>

            {/* Lang. switcher (desktop) */}
            <Box
              alignSelf="flex-start"
              flexShrink="0"
              mt="29px"
              display={{ base: 'none', md: 'block' }}
            >
              <LanguageSwitcher />
            </Box>
          </Flex>
        </GridItem>

        <GridItem
          colSpan="3"
          colStart="6"
          height="100%"
          className="rounded-corners rounded-corners--no-bp rounded-corners--bottom"
        >
          <Flex
            width="100%"
            height="100%"
            justifyContent="space-between"
            alignItems={{ base: 'center', md: 'flex-start' }}
            px={{ base: 0, md: 'sideSpace' }}
          >
            {/* Main museum link (desktop only) */}
            <NextChakraLink
              href={locale === 'da' ? 'https://snm.ku.dk/' : 'https://snm.ku.dk/en'}
              display={{ base: 'none', md: 'block' }}
              mt="29px"
              position="relative"
              _hover={{
                color: 'greyHover',
                '& .arrow-link': {
                  _after: {
                    transform: 'translate(2px, -2px)',
                  }
                }
              }}
            >
              <Text
                as="span"
                className="arrow-link arrow-link--external"
              >
                {t('go-to-the-museum-site')}
              </Text>
            </NextChakraLink>

            {/* Lang. switcher (mobile) */}
            <Box
              flexShrink="0"
              display={{ base: 'flex', md: 'none' }}
              justifyContent="center"
              alignItems="center"
              width="50%"
              height="100%"
            >
              <LanguageSwitcher />
            </Box>

            {/* Search */}
            <Box
              display={{ base: 'flex', md: 'block' }}
              justifyContent="center"
              alignItems="center"
              width={{ base: '50%', md: 'auto' }}
              height="100%"
              borderLeft={{ base: '1px', md: '0' }}
              className="rounded-corners rounded-corners--mobile-only rounded-corners--bottom"
            >
              <Button
                variant="outline"
                onClick={onOpen}
                ref={searchButtonRef}
                aria-expanded={isOpen}
                color='white'
                display="flex"
                borderColor="transparent"
                borderRadius="0"
                px="2"
                // Offset so text looks properly aligned
                mr={{ base: '0', md: '-8px' }}
                mt={{ base: '1', md: '21px' }}
                _hover={{
                  backgroundColor: 'black',
                  borderColor: 'white',
                }}
                _active={{
                  backgroundColor: 'black',
                }}
              >
                <Text as="span" mr="2" display={{ base: 'none', sm: 'inline' }}>{t('search-label')}</Text>
                <Icon viewBox="0 0 14.914 14.914" width={{ base: '1.1em', sm: '14.914px' }} height={{ base: '1.1em', sm: '14.914px' }}>
                  <g fill="none" fillRule="evenodd" stroke="#FFF" strokeWidth="2">
                    <circle cx="6" cy="6" r="5"/>
                    <path strokeLinecap="square" d="m10 10 3.5 3.5"/>
                  </g>
                  <circle cx="6" cy="6" r="5" fill="none" fillRule="evenodd" stroke="#FFF" strokeWidth="2"/>
                </Icon>
              </Button>

            </Box>
          </Flex>
        </GridItem>
      </Grid>

      {/* Search form */}
      <Drawer
        isOpen={isOpen}
        placement="right"
        size="lg"
        onClose={onClose}
        finalFocusRef={searchButtonRef}
        initialFocusRef={searchInputRef}
      >
        <DrawerOverlay />
        <DrawerContent bg="black" borderLeft="1px solid #fff">
          <DrawerCloseButton
            aria-label={t('close')}
            borderRadius="0"
            transition="background-color 0.3s, color 0.3s"
            _hover={{
              bg: 'white',
              color: 'black'
            }}
          />
          <DrawerBody mt="20">
            <SearchInput
              id="header"
              searchInputRef={searchInputRef}
              renderImagesOnlyCheckbox
              onSearchComplete={onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default Header;
