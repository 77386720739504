import {
  Box, Grid, GridItem, Image, Link, ListItem,
  Text, UnorderedList,
} from '@chakra-ui/react';
import { Link as NextLink } from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { ABOUT_LINKS, CONTACT_DETAILS, SOCIAL_LINKS } from '../../lib/constants/managed';
import NextChakraLink from '../NextChakraLink/NextChakraLink';

function LinksList({ links }) {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  return (
    <UnorderedList styleType="none" marginLeft="0" spacing="3">
      {links.map(({ href, label }) => (
        <ListItem key={label}>
          <Link
            as={NextLink}
            href={href[`${locale}`]}
            textStyle="link"
            position="relative"
            _hover={{
              color: 'greyHover',
              '& .arrow-link': {
                _after: {
                  transform: 'translateX(2px)',
                }
              }
            }}
          >
            <Text
              as="span"
              className="arrow-link"
            >{t(label)}</Text>
          </Link>
        </ListItem>
      ))}
    </UnorderedList>
  );
}

LinksList.propTypes = ({
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.shape(
        {
          da: PropTypes.string,
          en: PropTypes.string,
        },
      ),
      label: PropTypes.string,
    }),
  ).isRequired,
});

// TODO this is similar enough to the LinksList component that they could be combined
function SocialLinks({ links }) {
  return (
    <UnorderedList className="footer-social-links" styleType="none" marginLeft="0" spacing="3">
      {links.map(({ href, label }) => (
        <ListItem key={label}>
          <Link
            as={NextLink}
            href={href}
            isExternal
            textStyle="link"
            position="relative"
            _hover={{
              color: 'greyHover',
              '& .arrow-link': {
                _after: {
                  transform: 'translate(2px, -2px)',
                }
              }
            }}
          >
            <Text
              as="span"
              className="arrow-link arrow-link--external">{label}</Text>
          </Link>
        </ListItem>
      ))}
    </UnorderedList>
  );
}

SocialLinks.propTypes = ({
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
});

function Footer() {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  return (
    <Box
      as="footer"
      overflow="hidden"
      py="8"
      px="sideSpace"
      maxWidth="2000px"
      mx="auto"
      borderTop="solid 1px #fff"
      className="page-border"
    >
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap={{ base: 8, lg: 6 }}
      >
        {/* Museum logo */}
        <GridItem
          colSpan={{ base: 12, md: 6, lg: 3 }}
          rowStart={{ base: 1, md: 2 }}
        >
          <NextChakraLink
            href={locale === 'da' ? 'https://snm.ku.dk/' : 'https://snm.ku.dk/english'}
            display="block"
          >
            <Image
              src={`/images/logos/SNM_${locale || 'da'}_logo_white.svg`}
              alt={t('natural-history-museum-denmark')}
              maxWidth="260px"
            />
          </NextChakraLink>
        </GridItem>

        {/* Info */}
        <GridItem
          colSpan={{ base: 12, md: 4, lg: 3 }}
          colStart={{ base: 1, lg: 4 }}
          rowStart={{ base: 2, md: 1 }}
        >
          {/* Statens Naturhistoriske Museum */}
          <Text textStyle="h7" fontWeight="700">
            {t('natural-history-museum-denmark')}
          </Text>

          {/* Address */}
          <Text textStyle="h7">
            Øster Voldgade 5 – 7<br/>
            1350 København K
          </Text>

          {/* Contact details */}
          <Text textStyle="h7" mt="4">
            <NextChakraLink href={`mailto:${CONTACT_DETAILS.email}`}>{CONTACT_DETAILS.email}</NextChakraLink><br/>
            <NextChakraLink href={`tel:${CONTACT_DETAILS.phone.replace(/\s/g, '')}`}>{CONTACT_DETAILS.phone}</NextChakraLink><br/>
            {locale === 'en' ? CONTACT_DETAILS.enPhoneHours :  CONTACT_DETAILS.daPhoneHours}
          </Text>
        </GridItem>

        {/* Main links list */}
        <GridItem
          colSpan={{ base: 12, md: 4, lg: 3 }}
          colStart={{ base: 1, md: 5, lg: 7 }}
          rowStart={{ base: 3, md: 1 }}
        >
          <LinksList links={ABOUT_LINKS} />
        </GridItem>

        {/* Social links */}
        <GridItem
          colSpan={{ base: 12, md: 4, lg: 3 }}
          colStart={{ base: 0, md: 9, lg: 10 }}
          rowStart={{ base: 4, md: 1 }}
          zIndex="1"
          position="relative"
        >
          <SocialLinks links={SOCIAL_LINKS} />
        </GridItem>

        <GridItem
          colSpan={{ base: 12, md: 6 }}
          colStart={{ base: 0, md: 1, lg: 4 }}
          rowStart={{ base: 5, md: 3, lg: 2 }}
          alignSelf="end"
          display="flex"
          gap="1em"
          flexDirection={{ base: 'column', lg: 'row' }}
        >
          {/* Accessibility */}
          <Link
            as={NextLink}
            href="/"
            textStyle="link"
            display="flex"
            _hover={{
              color: 'greyHover',
              '& .arrow-link': {
                _after: {
                  transform: 'translateX(2px)',
                }
              }
            }}
          >
            <Text
              className="arrow-link">{t('accessibility')}</Text>
          </Link>
        </GridItem>

        {/* Parkmuseerne/Copenhagen Uni logos */}
        <GridItem
          colSpan={{ base: 12, md: 6, lg: 3 }}
          colStart={{ base: 0, md: 7, lg: 10}}
          rowStart={{ base: 7, md: 2 }}
          alignSelf="end"
          display="flex"
          gap="4"
          alignItems="flex-end"
        >

          {/* Parkmuseerne */}
          <NextChakraLink
            href={locale === 'da' ? 'https://www.parkmuseerne.dk/' : 'https://www.parkmuseerne.dk/en'}
            display="block"
            height="58px"
            width="78px"
          >
            <Image
              src="/images/logos/PM_da_logo_white.svg"
              alt="Park Museerne"
            />
          </NextChakraLink>

          {/* Copenhagen Uni */}
          <NextChakraLink
            href={locale === 'da' ? 'https://www.ku.dk/' : 'https://www.ku.dk/english/'}
            display="block"
            height="42px"
            width="188px"
          >
            {/* TODO: Get English version of this logo */}
            <Image
              src={`/images/logos/KU_${locale}_logo_white.svg`}
              alt={t('university-of-copenhagen')}
            />
          </NextChakraLink>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Footer;
