import { SearchIcon } from '@chakra-ui/icons';
import {
  Box, Checkbox, Flex, IconButton, Input, InputGroup, InputRightElement, List, ListItem, Text
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { filterObject } from '../../lib/helpers';

function SearchInput({
  id, onSearchComplete, searchInputRef, searchTerm, renderImagesOnlyCheckbox, prewarm,
}) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const searchRef = useRef();
  const selectedItemRef = useRef();
  const [isFetchSuggestions, setIsFetchSuggestions] = useState(false);
  const [term, setTerm] = useState(searchTerm);
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [isImagesOnlyChecked, setIsImagesOnlyChecked] = useState(false);

  const numberOfResults = 5;

  // On initial load of this component, pings the API for suggestions
  // to pre-populate the search box. This is only done once to reduce,
  // effect of a cold start on the Vercel serverless function.
  useEffect(() => {
    if (!prewarm) {
      return;
    }

    // Don't run if there's a user supplied search term.
    if (searchTerm) {
      return;
    }

    async function prewarmApi() {
      // Generate a random string to avoid caching.
      const randomString = Math.random().toString(36).substring(7);
      const url = `/api/suggestions/zz${randomString}`;

      // Swallow any response errors, Sentry will flag errors in the API.
      try {
        await fetch(url);
      }
      catch (e) {
        console.log(e);
      }
    }

    prewarmApi();
    // We only want to run this once, so we don't add any dependencies.
  }, []);


  useEffect(() => {
    if (searchTerm) {
      setTerm(decodeURIComponent(searchTerm));
    } else {
      setTerm('');
    }
    setIsFetchSuggestions(true);
  }, [searchTerm]);

  useEffect(() => {
    // Don't trigger a request when the search term isn't updated by the user
    if (!isFetchSuggestions) {
      setIsFetchSuggestions(true);
      return;
    }

    async function getSuggestions() {
      const { locale } = router;
      const url = `/api/suggestions/${term}?locale=${locale}${isImagesOnlyChecked ? '&hasImage=true' : ''}`;
      let newSuggestions = await fetch(url).then((res) => res.json());

      if (newSuggestions.length > 0) {
        if (newSuggestions.length > numberOfResults) {
          newSuggestions = newSuggestions.slice(0, numberOfResults + 1);
        }

        setIsSuggestionsVisible(true);
        setSuggestions(newSuggestions);
      } else {
        setIsSuggestionsVisible(false);
        setSuggestions([]);
      }
    }

    if (term !== router.query.q) {
      getSuggestions();
    }
  }, [term]);

  const submit = (suggestion) => {
    let { query } = router;
    query.q = suggestion || term;
    if (isImagesOnlyChecked) {
      query.imagesOnly = 'true';
    }

    // For a new search only add the q and imagesOnly params
    query = filterObject(query, ['q', 'imagesOnly']);

    router.push({
      pathname: '/search',
      query,
    }).then(() => {
      if (onSearchComplete) {
        onSearchComplete();
      }
    });
  };

  const onSuggestionClick = (selectedResult) => {
    setIsFetchSuggestions(false);
    setTerm(selectedResult.canonical);
    setIsSuggestionsVisible(false);
    submit(selectedResult.canonical);
  };

  const handleClickOutside = (e) => {

    // Inside click
    if (searchRef.current.contains(e.target)) {
      setIsSuggestionsVisible(true);
      return;
    }

    // Outside click
    setIsSuggestionsVisible(false);
  };

  useEffect(() => {
    if (isSuggestionsVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSuggestionsVisible]);

  const handleClick = () => {
    submit();
  };

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.target.id !== `search-input--${id}`) {
      return;
    }

    switch (event.code) {
    case 'ArrowUp':
      event.preventDefault();
      setSelectedItemIndex((prevSelectedItemIndex) => (prevSelectedItemIndex > -1
        ? prevSelectedItemIndex - 1
        : suggestions.length - 1));
      break;

    case 'ArrowDown':
      event.preventDefault();
      setSelectedItemIndex((prevSelectedItemIndex) => (
        prevSelectedItemIndex < suggestions.length - 1
          ? prevSelectedItemIndex + 1
          : 0
      ));
      break;

    case 'Enter':
      event.preventDefault();
      if (suggestions.length && selectedItemIndex >= 0) {
        const selectedResult = suggestions[selectedItemIndex];
        if (selectedResult) {
          onSuggestionClick(selectedResult);
        } else {
          handleClick();
        }
      } else {
        submit();
      }
      break;

    case 'Escape':
      setIsSuggestionsVisible(false);
      setSelectedItemIndex(-1);
      break;

    case 'Tab':
      setIsSuggestionsVisible(false);
      break;

    case 'Home':
      event.preventDefault();
      setSelectedItemIndex(0);
      break;

    case 'End':
      event.preventDefault();
      setSelectedItemIndex(suggestions.length - 1);
      break;

    default:
    }
  };

  return (
    <Box className="search" ref={searchRef} position="relative">
      <InputGroup size="lg">
        <Input
          autoComplete="off"
          id={`search-input--${id}`}
          type="text"
          aria-autocomplete="list"
          aria-controls={`search-results-listbox--${id}`}
          aria-activedescendant={
            isSuggestionsVisible && !!suggestions.length ? `result-item--${id}--${selectedItemIndex}` : ''
          }
          placeholder={t('search-label')}
          variant="flushed"
          size="lg"
          textStyle="h5"
          value={term}
          onChange={handleChange}
          onFocus={() => setIsSuggestionsVisible(true)}
          onKeyDown={handleKeyDown}
          ref={searchInputRef}
          _focusVisible={{
            boxShadow: 'outline'
          }}
        />
        <InputRightElement>
          <IconButton
            onClick={handleClick}
            aria-label={t('search-label')}
            variant="unstyled"
            size="lg"
            icon={<SearchIcon />}
            borderRadius="0"
            transition="background-color 0.3s, color 0.3s"
            _hover={{
              bg: 'white',
              color: 'black'
            }}
          />
        </InputRightElement>
      </InputGroup>
      <List
        id={`search-results-listbox--${id}`}
        role="listbox"
        hidden={!isSuggestionsVisible || !suggestions.length}
        bg="gray.900"
        borderColor="gray.700"
        borderWidth="2px"
        borderRadius="md"
        marginTop="1"
        position="absolute"
        zIndex="2"
        width="full"
      >
        {suggestions.map((suggestion, i) => {
          const {
            vernacular, canonical, authorship, key,
          } = suggestion;
          const listItemKey = `${key.toString()}`;
          return (
            <ListItem
              id={`result-item-${i}`}
              className="site-search__result"
              aria-selected={i === selectedItemIndex}
              key={listItemKey}
              onClick={() => onSuggestionClick(suggestion)}
              ref={i === selectedItemIndex ? selectedItemRef : null}
              role="option"
              bg={selectedItemIndex === i ? 'gray.600' : 'inherit'}
              boxShadow="md"
              px={2}
              py={1}
              _hover={{ bg: 'gray.600' }}
            >
              {vernacular
                ? <b>{vernacular}</b>
                : <b>{canonical}</b> }<br />
              {vernacular
                ? <span>{canonical}</span>
                : <span><i>{canonical}</i> {authorship}</span>}<br />
            </ListItem>
          );
        })}
      </List>
      {renderImagesOnlyCheckbox && (
        <Flex mt="4" justify="flex-end">
          <Checkbox
            name="imagesOnly"
            checked={isImagesOnlyChecked}
            value="true"
            onChange={() => setIsImagesOnlyChecked(!isImagesOnlyChecked)}
            sx={{
              '.chakra-checkbox__control': {
                border: `1px solid ${'#fff'}`,
                borderRadius: 0,
              },
            }}
            _checked={{
              '& .chakra-checkbox__control': {
                color: '#fff' === 'black' ? '#fff' : 'black',
                backgroundColor: '#fff',
                borderColor: '#fff'
              },
            }}
            _hover={{
              '& .chakra-checkbox__control': {
                color: '#fff' === 'black' ? '#fff' : 'black',
                backgroundColor: '#fff',
              },
            }}
          >
            <Text textStyle="link" fontWeight="500">{t('only-show-images')}</Text>
          </Checkbox>
        </Flex>
      )}
    </Box>
  );
}

SearchInput.propTypes = {
  id: PropTypes.string,
  searchInputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  onSearchComplete: PropTypes.func,
  searchTerm: PropTypes.string,
  renderImagesOnlyCheckbox: PropTypes.bool,
  prewarm: PropTypes.bool,
};

SearchInput.defaultProps = {
  id: '',
  searchInputRef: null,
  searchTerm: '',
  onSearchComplete: null,
  renderImagesOnlyCheckbox: false,
  prewarm: false,
};

export default SearchInput;
