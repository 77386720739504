/* eslint-disable react/jsx-props-no-spreading */
import { Link as ChakraLink } from '@chakra-ui/react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

// Recommended by Chakra UI to use NextLink with ChakraLink
// Based on https://v2.chakra-ui.com/docs/components/link#usage-with-nextjs
function NextChakraLink({
  href,
  replace,
  scroll,
  shallow,
  prefetch,
  children,
  locale,
  ...chakraProps
}) {
  return (
    <ChakraLink
      as={NextLink}
      {...{
        passHref: true,
        href,
        replace,
        scroll,
        shallow,
        prefetch,
        // Only pass locale prop to NextLink if it has a value
        ...(locale ? { locale } : {}),
      }}
      {...chakraProps}
    >
      {children}
    </ChakraLink>
  )
}

NextChakraLink.propTypes = {
  href: PropTypes.string.isRequired,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  shallow: PropTypes.bool,
  prefetch: PropTypes.bool,
  children: PropTypes.node,
  locale: PropTypes.string,
};

NextChakraLink.defaultProps = {
  replace: false,
  scroll: true,
  shallow: false,
  prefetch: false,
  children: null,
  locale: null,
};

export default NextChakraLink;
