import * as countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/da.json'));

const getCountryName = (code, locale) => countries.getName(code, locale, { select: 'official' });

/**
 * Helper function to transform a query object.
 *
 * Replaces falsey values with empty strings.
 *
 * Use the map argument if your output query needs to
 * use different keys to those used in the input.
 *
 * e.g. you want to use different params in the URL
 * to those used by your backend.
 *
 * @param {object} query
 *  A query object. e.g.
 *  {
 *    q: 'foo',
 *    filter: 'bar',
 *    otherparam: 'baz'
 *  }
 * @param {object} map
 *  Mapping used to rename properties on the object.
 * @returns object
 *  The transformed query object.
 */
const transformQueryObject = (query, map = {}) => {
  const params = {};

  Object.keys(query).forEach((key) => {
    const mappedKey = map[key] || key;
    params[mappedKey] = query[key] ? query[key] : '';
  });

  return params;
};

/**
 * Helper to filter an object by property names.
 *
 * @param {object} object
 * @param {array} properties A list of properties to include in the returned object.
 * @returns object
 */
const filterObject = (object, properties = []) => Object.fromEntries(
  Object.entries(object).filter(([key]) => (properties.length ? properties.includes(key) : true)),
);

export {
  filterObject,
  getCountryName,
  transformQueryObject,
};
